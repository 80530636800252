<template>
  <div>
    <BCarousel :indicator="false" :interval="5000">
      <BCarouselItem v-for="n in 6" :key="n">
        <section>
          <div class="soy-referee-carousel__image-container" @click="onClick(n)">
            <img
              :src="`https://az755631.vo.msecnd.net/b2c-slider-publicitario/slide_${screenType}${n}.jpg`"
              alt="img"
              @load="onLoad"
            />
          </div>
        </section>
      </BCarouselItem>
    </BCarousel>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
import {
  BannerOurSuggestions,
  BannerScientist,
  BannerTipScientist,
  BannerParlay,
  BannerFreePicks,
  BannerTechBet,
} from '@/utils/analytics';

export default {
  name: 'SoyReferee',
  components: {
    BCarousel,
    BCarouselItem,
  },
  data() {
    return {
      screenType: 'pc', // desktop or mobile
      imagesHeight: 0,
    };
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        if (newValue > 600) {
          this.screenType = 'pc';
        } else {
          this.screenType = 'movil';
        }
      },
    },
  },
  computed: {
    ...mapState(['displayWidth']),
  },
  created() {
    this.sendBannerLog(0, 0);
  },
  mounted() {
    this.sendBannerLog(0, 1);
  },
  methods: {
    onClick(n) {
      let events = {
        1: () => BannerOurSuggestions(),
        2: () => BannerScientist(),
        3: () => BannerTipScientist(),
        4: () => BannerParlay(),
        5: () => BannerFreePicks(),
        6: () => BannerTechBet(),
      };
      if (events.hasOwnProperty(n)) {
        events[n]();
      }
      this.sendBannerLog(n, 1);
      window.open('https://soyreferee.com/p/contenido/estadisticas.html');
    },
    onLoad($event) {
      const img = $event.path[0];
      if (img) {
        if (this.imagesHeight !== img.height) {
          this.imagesHeight = img.height;
          this.$emit('soy-referee-change', this.imagesHeight);
        }
      }
    },
    async sendBannerLog(bannerType, type) {
      try {
        const logsUrl = 'https://95ow8l4hkh.execute-api.us-east-2.amazonaws.com/prod/banners/logs';
        const body = {
          referral: document.referrer || document.location.host,
          banner_type: bannerType,
          type,
          device_type: this.deviceType(),
        };
        await axios.post(logsUrl, body);
      } catch (e) {
        throw new Error(e);
      }
    },
    deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 3;
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
      ) {
        return 2;
      }
      return 1;
    },
  },
};
</script>

<style scoped lang="scss">
.soy-referee-carousel__image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-color: #f5f5f5;
}
</style>
<style>
#app {
  background-image: none !important;
  background-size: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
body {
  padding-top: 0 !important;
  height: fit-content;
}
</style>
